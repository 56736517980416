@import '/styles/config.scss';

.content {
    @include grid-item(8, 2);
    display: flex;
    flex-direction: column;

    @media (max-width: $min-desktop) {
        @include grid-item(10, 1);
    }

    @media (max-width: $min-tablet) {
        @include grid-item(12, 0);
    }
}

.reason {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.button {
    margin-top: 2em;
    display: flex;
}
